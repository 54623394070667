import './App.css';
import Timeline from './Timeline/Timeline';
import Topbar from './Topbar';
import NavigationMobile from './NavigationMobile';
import Div100vh from 'react-div-100vh';
import { useToggle } from 'react-use';
import InfoModal from './Modal/InfoModal';
import ShareModal from './Modal/ShareModal';
import useMapPlayer from './utils/useMapPlayer';
import Map from './Map/Map';
import { useRef, useState } from 'react';
import MapSwitcher from './Map/MapSwitcher';
import classNames from 'classnames';
import useUrlParams from './utils/useUrlParams';
import useMapSync from './utils/useMapSync';

type AppProps = {
  compare?: boolean;
};

function App({ compare = false }: AppProps) {
  const [shareModalOpen, toggleShareModal] = useToggle(false);
  const [infoModalOpen, toggleInfoModal] = useToggle(false);
  const [mobileNavigationOpen, toggleMobileNavigation] = useToggle(false);
  const { playing, togglePlay, secondaryPlaying, toggleSecondaryPlay } =
    useMapPlayer();
  const mobileSearchInstance = useRef<any>();
  const [snack, setSnack] = useState<string | false>(false);
  const [primaryMapRef, secondaryMapRef] = useMapSync(compare);
  const { activeYear, mapType, activeYearCompare, mapTypeCompare } =
    useUrlParams();

  return (
    <Div100vh className={classNames('Layout', { Layout___compare: compare })}>
      <Topbar
        toggleMobileNavigation={toggleMobileNavigation}
        mobileNavigationOpen={mobileNavigationOpen}
        toggleShareModal={toggleShareModal}
        toggleInfoModal={toggleInfoModal}
        mobileSearchInstance={mobileSearchInstance}
        snack={snack}
        togglePlay={togglePlay}
        toggleSecondaryPlay={toggleSecondaryPlay}
        setSnack={setSnack}
      />

      <Timeline
        playing={playing}
        togglePlay={togglePlay}
        mapType={mapType}
        activeYear={activeYear}
      />

      <Map
        mobileSearchInstance={mobileSearchInstance}
        mapType={mapType}
        mapTypeCompare={mapTypeCompare}
        activeYear={activeYear}
        ref={primaryMapRef}
      >
        <MapSwitcher
          setSnack={setSnack}
          mapType={mapType}
          activeYear={activeYear}
        />
      </Map>

      <NavigationMobile
        playing={playing}
        togglePlay={togglePlay}
        toggleSecondaryPlay={toggleSecondaryPlay}
        setSnack={setSnack}
        isOpen={mobileNavigationOpen}
        toggle={toggleMobileNavigation}
        toggleShareModal={toggleShareModal}
        toggleInfoModal={toggleInfoModal}
        modalOpen={shareModalOpen || infoModalOpen}
      />

      {compare && (
        <>
          <Map
            mobileSearchInstance={mobileSearchInstance}
            mapType={mapTypeCompare}
            mapTypeCompare={mapType}
            activeYear={activeYearCompare}
            ref={secondaryMapRef}
            secondary
          >
            <MapSwitcher
              setSnack={setSnack}
              mapType={mapTypeCompare}
              activeYear={activeYearCompare}
              secondary
            />
          </Map>
          <Timeline
            playing={secondaryPlaying}
            togglePlay={toggleSecondaryPlay}
            mapType={mapTypeCompare}
            activeYear={activeYearCompare}
            secondary
          />
        </>
      )}

      <InfoModal isOpen={infoModalOpen} handleClose={toggleInfoModal} />
      <ShareModal isOpen={shareModalOpen} handleClose={toggleShareModal} />
    </Div100vh>
  );
}

export default App;
