import { generatePath } from 'react-router';
import { MapTypes } from './mapUtils';

export const splitMapState = (mapState?: string) => {
  if (!mapState) return undefined;
  let splittedState;
  splittedState = mapState.substring(1).split(',');
  splittedState = splittedState.map(function (x) {
    return parseFloat(x);
  });
  return { x: splittedState[0], y: splittedState[1], zoom: splittedState[2] };
};

export const joinMapState = ({ x, y, zoom }: any) => {
  return `@${Math.round(x)},${Math.round(y)},${Math.round(zoom * 100) / 100}`;
};

export type CreateUrl = {
  type?: MapTypes;
  year?: number;
  typeCompare?: MapTypes;
  yearCompare?: number;
  mapState?: string;
  comparing?: boolean;
};

export const createUrl = ({
  type,
  year,
  mapState,
  typeCompare,
  yearCompare,
  comparing,
}: CreateUrl) => {
  const translatedType = type === 'satellite' ? 'satelliet' : 'kaart';
  const translatedTypeCompare =
    typeCompare === 'satellite' ? 'satelliet' : 'kaart';

  let url;
  if (comparing) {
    url = '/vergelijk/:type/:year/:typeCompare/:yearCompare';
  } else {
    url = '/:type/:year';
  }

  if (mapState) {
    url = url + '/:mapState';
  }

  return generatePath(url, {
    type: translatedType,
    year: year?.toString(),
    typeCompare: translatedTypeCompare,
    yearCompare: yearCompare?.toString(),
    mapState,
  });
};
