import { useMediaQuery } from 'react-responsive';
import useUrlParams from './useUrlParams';

const useToggleCompare = (
  setSnack: (message: string | false) => void,
  togglePlay: (toggle?: boolean) => void,
  toggleSecondaryPlay: (toggle?: boolean) => void
) => {
  const landscapeMode = useMediaQuery({ query: '(min-width: 600px)' });
  const { navigateTo, comparing } = useUrlParams();
  return () => {
    // Stop playing maps when switching comparing state
    if (!comparing && landscapeMode) {
      setSnack(
        'Gebruik de tijdbalk rechts om de tweede kaart van jaar te wisselen.'
      );
    }
    togglePlay(false);
    toggleSecondaryPlay(false);
    navigateTo({ comparing: !comparing });
  };
};

export default useToggleCompare;
