import { useInterval, useToggle } from 'react-use';
import settings from '../settings.json';
import useUrlParams from './useUrlParams';
import { getMapSettings } from './mapUtils';
import { useEffect } from 'react';

const useMapPlayer = () => {
  const [playing, togglePlay] = useToggle(false);
  const [secondaryPlaying, toggleSecondaryPlay] = useToggle(false);
  const { activeYear, activeYearCompare, mapType, mapTypeCompare, navigateTo } =
    useUrlParams();
  const mapSettings = getMapSettings(mapType);
  const mapSettingsCompare = getMapSettings(mapTypeCompare);

  const advance = () => {
    let nextYear = activeYear;
    let nextYearCompare = activeYearCompare;

    if (playing) {
      nextYear =
        activeYear + 1 > mapSettings.lastYear
          ? mapSettings.firstYear
          : activeYear + 1;
    }
    // Also fix stop of timer of secondary when comparing is closing
    if (secondaryPlaying) {
      nextYearCompare =
        activeYearCompare + 1 > mapSettingsCompare.lastYear
          ? mapSettingsCompare.firstYear
          : activeYearCompare + 1;
    }

    navigateTo({ year: nextYear, yearCompare: nextYearCompare });
    // Mischien een ternary setting?
  };

  useInterval(
    advance,
    playing || secondaryPlaying ? settings.timeLoopIntervalSeconds * 1000 : null
  );

  // Advance map directly when pressing play
  useEffect(() => {
    if (playing && !secondaryPlaying) advance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    if (!playing && secondaryPlaying) advance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryPlaying]);

  return { playing, togglePlay, secondaryPlaying, toggleSecondaryPlay };
};

export default useMapPlayer;
