import {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
} from 'react';
import classNames from 'classnames';
import styles from './Map.module.css';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import Locate from '@arcgis/core/widgets/Locate';
import * as watchUtils from '@arcgis/core/core/watchUtils';
import WebMap from '@arcgis/core/WebMap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import useUrlParams from '../utils/useUrlParams';
import { joinMapState, splitMapState } from '../utils/urlUtils';
import { getMapSettings, lods, MapTypes } from '../utils/mapUtils';
import { useParams } from 'react-router-dom';
import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import Attribution from '@arcgis/core/widgets/Attribution';
import Graphic from '@arcgis/core/Graphic';
import Zoom from '@arcgis/core/widgets/Zoom';
import CustomAttribution from './CustomAttribution';
import { LocatorService } from '../settings.json';

const geolocationSource = {
  url: LocatorService,
  outFields: ['Addr_type'],
  placeholder: 'Zoek op de kaart',
  singleLineFieldName: 'SingleLine',
};

type MapProps = {
  mobileSearchInstance: any;
  children: React.ReactNode;
  secondary?: boolean;
  comparing?: boolean;
  activeYear: number;
  mapType: MapTypes;
  mapTypeCompare: MapTypes;
};

function Map(
  {
    mobileSearchInstance,
    children,
    mapType,
    activeYear,
    mapTypeCompare,
    secondary = false,
  }: MapProps,
  ref: any
) {
  // const mapDiv = useRef(null);
  const mapRef = useRef<WebMap>(null!);
  const mapViewRef = useRef<MapView>(null!);
  const attributionRef = useRef<Attribution>(null!);
  const layerRef = useRef<TileLayer>(null!);
  const [domLoading, setDomLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [loading, setIsLoading] = useState(true);
  const prevExtentRef = useRef<any>(null!);
  const prevZoomRef = useRef<any>(null!);
  const { navigateTo, comparing } = useUrlParams();
  let { mapState } = useParams();

  useImperativeHandle(ref, () => ({
    getView: () => mapViewRef.current,
  }));

  const mapDiv = useCallback((node) => {
    if (node) {
      mapRef.current = new WebMap();
      const state = splitMapState(mapState);
      const view = new MapView({
        container: node,
        zoom: state && state.zoom ? state.zoom : undefined,
        map: mapRef.current,
        spatialReference: new SpatialReference({ wkid: 28992 }),
        constraints: {
          snapToZoom: false,
          minZoom: 2,
          // @ts-ignore
          lods: lods,
          maxZoom: 16,
          rotationEnabled: false,
        },
        padding: { top: 65, bottom: 0 },
      });
      mapViewRef.current = view;
      view.ui.components = [];

      // Set initial mapCenter from the url
      if (state && state.x && state.y) {
        view.center = new Point({
          x: state.x,
          y: state.y,
          spatialReference: view.spatialReference,
        });
      }

      const zoom = new Zoom({ view: view });
      view.ui.add(zoom);

      if (!secondary) {
        new Search({
          view: view,
          includeDefaultSources: false,
          sources: [geolocationSource],
          container: 'searchWidget',
          popupEnabled: false,
          resultGraphicEnabled: true,
          locationEnabled: false,
        });

        // Mobile
        mobileSearchInstance.current = new Search({
          view: view,
          includeDefaultSources: false,
          sources: [geolocationSource],
          container: 'mobileSearchWidget',
          popupEnabled: false,
          resultGraphicEnabled: true,
          locationEnabled: false,
        });
      }

      const locateWidget = new Locate({
        view: view, // Attaches the Locate button to the view
        graphic: new Graphic({
          symbol: {
            // @ts-ignore - Types not correct in ArcgisJS 4
            type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
            color: '#00387d',
            size: 10,
            outline: { width: 1, color: 'white' },
          },
        }),
      });

      view.ui.add(locateWidget, 'manual');

      const scaleBar = new ScaleBar({
        unit: 'metric',
        view: view,
      });

      const attribution = new Attribution({
        view: view,
        // @ts-ignore
        visible: true,
      });
      attributionRef.current = attribution;

      view.ui.add(attribution);

      view.ui.add(scaleBar, {
        position: 'bottom-right',
      });

      // Set the initial extent on loaded so we know if we have to change the url
      view.when(() => {
        setLoading(false)
        prevExtentRef.current = view.extent;
        prevZoomRef.current = view.zoom;
        // setIsLoading(false);
      });
    }
    // All refs are set, dom is ready
    // setTimeout(()=>{
    setDomLoading(false);
    // }, 1000);
    // No dependencies, we only want this function triggered once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Change the maxzoom based no the type of map we're looking at
  useEffect(() => {
    if (loading) return;
    const view = mapViewRef.current;
    // If we're comparing the same mapTypes, we can use the max zoomLevel of the maptype
    let maxZoom = 12;
    if (!comparing || mapType === mapTypeCompare) {
      maxZoom = mapType === 'satellite' ? 14 : 12;
    }
    // If our currentzoom is larger than the maxzoom, update url
    if (view.extent && mapViewRef.current.zoom > maxZoom) {
      navigateTo(
        {
          mapState: joinMapState({
            x: view.extent.center.x,
            y: view.extent.center.y,
            zoom: maxZoom,
          }),
        },
        { replace: true }
      );
    }
    view.constraints.maxZoom = maxZoom;
  }, [mapType, comparing, mapTypeCompare, navigateTo, loading]);

  // Change map layer based on active year, only load layer after dom is ready
  useEffect(() => {
    if (domLoading) return;
    // Find the MapLayer from settings
    const mapLayer = getMapSettings(mapType).layers.find((layer) => {
      return activeYear >= layer.from && activeYear <= layer.to;
    });

    if (!mapLayer) return;

    // @ts-ignore
    attributionRef.current.visible = mapLayer.attribution ? false : true;

    let layer = new TileLayer({
      url: mapLayer.url,
      id: mapLayer.id.toString(),
      resampling: true,
      maxScale: 187.5,
    });
    mapRef.current.add(layer);
    if (layerRef.current) {
      const prevLayer = mapRef.current.findLayerById(layerRef.current.id);
      mapRef.current.remove(prevLayer);
    }
    layerRef.current = layer;
  }, [mapType, activeYear, domLoading]);

  // Trigger viewchange
  useEffect(() => {
    if (loading) return;
    const view = mapViewRef.current;
    // Update the initialExtend, so that we navigate unessecarily
    prevExtentRef.current = mapViewRef.current.extent;
    prevZoomRef.current = mapViewRef.current.zoom;

    // Watch viewport changes to update the url. We need to do it here to
    // prevent stale mapType and activeYear values in createUrl.
    const viewWatch = watchUtils.whenFalse(view, 'stationary', () => {
      // @hier zit de bug op samsung a51 chrome
      // https://topotijdreis.nl/vergelijk/kaart/1865/kaart/1948/@178548,506942,4.4
      if (!view.stationary) {
        watchUtils.whenTrueOnce(view, 'stationary', () => {
          if (
            (view.zoom !== prevZoomRef.current ||
              view.extent.center.x !== prevExtentRef.current.center.x ||
              view.extent.center.y !== prevExtentRef.current.center.y) &&
            view.extent && view.extent.center
          ) {
            navigateTo(
              {
                mapState: joinMapState({
                  x: view.extent.center.x,
                  y: view.extent.center.y,
                  zoom: view.zoom,
                }),
              },
              { replace: true }
            );
          }
        });
      }
    });
    return () => viewWatch.remove();
  }, [navigateTo, loading]);

  let portalId = 'activeYearPortal';
  if (secondary) portalId = portalId + 'Secondary';

  return (
    <div
      className={classNames(styles.Map, styles.Map___default, {
        [styles.Map___secondary]: secondary,
        [styles.Map___primary]: !secondary && comparing,
      })}
    >
      <div className={styles.Map_holder} ref={mapDiv}></div>
      {children}
      <div id={portalId} />
      <CustomAttribution
        mapType={mapType}
        activeYear={activeYear}
        zoom={splitMapState(mapState)?.zoom}
      />
    </div>
  );
}

export default forwardRef(Map);
