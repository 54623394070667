import { RefObject, useEffect } from 'react';
import { useRafState } from 'react-use';

import { off, on } from 'react-use/esm/misc/util';

// Based on useMouse from react-use.

export type elY = number | null;

const useMouse = (ref: RefObject<Element>, active: boolean = false): elY => {
  if (process.env.NODE_ENV === 'development') {
    if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
      console.error('useMouse expects a single ref argument.');
    }
  }

  const [elY, setElY] = useRafState<elY>(null);

  useEffect(() => {
    const moveHandler = (event: MouseEvent | TouchEvent) => {
      if (active && ref && ref.current) {
        const { top } = ref.current.getBoundingClientRect();
        const posY = top + window.pageYOffset;
        const elY =
          event instanceof MouseEvent
            ? event.pageY - posY
            : event.touches[0].pageY - posY;
        setElY(elY);
      }
    };

    if (!active) {
      setElY(null);
    }

    on(document, 'mousemove', moveHandler);
    on(document, 'touchmove', moveHandler);

    return () => {
      off(document, 'mousemove', moveHandler);
      off(document, 'touchmove', moveHandler);
    };
  }, [ref, active, setElY]);

  return elY;
};

export default useMouse;
