import React from 'react';
import classNames from 'classnames';
import styles from './Icon.module.css';

type IconProps = {
  svg: Function;
  title: string;
  className?: string;
};

export default React.memo(function Icon({ svg, title, className }: IconProps) {
  // Width and height 100% is a safari fix for SVG
  let ImportedSvg: Function | undefined;
  ImportedSvg = svg;
  return (
    <span className={classNames(styles.Icon, className)}>
      <ImportedSvg width="100%" height="100%" role="img" title={title} />
    </span>
  );
});
