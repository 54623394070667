import { useEffect, useRef } from 'react';

const useMapSync = (compare: boolean) => {
  const primaryMapRef = useRef<any>();
  const secondaryMapRef = useRef<any>();
  useEffect(() => {
    if (compare) {
      const view1 = primaryMapRef.current.getView();
      const view2 = secondaryMapRef.current.getView();

      const views = [view1, view2];
      let active: any;

      const sync = (source: any) => {
        if (!active || !active.viewpoint || active !== source) return;

        for (const view of views) {
          if (view !== active) view.viewpoint = active.viewpoint;
        }
      };

      const activate = (view: any) => {
        active = view;
        sync(active);
      };

      for (const view of views) {
        view.watch(['interacting', 'animation'], () => {
          activate(view);
        });

        view.watch('viewpoint', () => sync(view));
      }
    }
  }, [compare]);
  return [primaryMapRef, secondaryMapRef];
};

export default useMapSync;
