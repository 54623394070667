import styles from './Topbar.module.css';
import Button from './shared/Button';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as IconCompare } from './icons/layout-two-colums.svg';
import { ReactComponent as IconSearch } from './icons/search.svg';
import { ReactComponent as IconMap } from './icons/maps.svg';
import { ReactComponent as IconNavigation } from './icons/navigation-menu.svg';
import { ReactComponent as IconShare } from './icons/share-1.svg';
import { ReactComponent as IconClose } from './icons/close.svg';
import { useEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import useOnClickOutside from './utils/useOnClickOutside';
import useUrlParams from './utils/useUrlParams';
import useToggleCompare from './utils/useToggleCompare';

type TopbarProps = {
  toggleMobileNavigation: (open?: boolean) => void;
  mobileNavigationOpen: boolean;
  toggleShareModal: () => void;
  toggleInfoModal: () => void;
  togglePlay: (toggle?: boolean) => void;
  toggleSecondaryPlay: (toggle?: boolean) => void;
  mobileSearchInstance: any;
  snack: string | false;
  setSnack: (message: string | false) => void;
};

export default function Topbar({
  toggleMobileNavigation,
  mobileNavigationOpen,
  toggleShareModal,
  toggleInfoModal,
  mobileSearchInstance,
  snack,
  setSnack,
  togglePlay,
  toggleSecondaryPlay,
}: TopbarProps) {
  const [mobileSearch, toggleMobileSearch] = useToggle(false);
  const nodeRef = useRef(null);
  const snackRef = useRef(null);
  const { comparing } = useUrlParams();
  const toggleCompare = useToggleCompare(
    setSnack,
    togglePlay,
    toggleSecondaryPlay
  );

  useOnClickOutside(
    nodeRef,
    () => {
      if (mobileSearch) toggleMobileSearch();
    },
    '#toggleSearch'
  );

  // Close the search when we activate the search
  useEffect(() => {
    if (mobileSearchInstance.current) {
      mobileSearchInstance.current.on('select-result', toggleMobileSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileSearchInstance.current, toggleMobileSearch]);

  return (
    <div className={styles.Topbar}>
      <div className={styles.Topbar_actionsMobile}>
        <Button
          type="transparent"
          iconTitle="Zoeken"
          id="toggleSearch"
          iconSvg={IconSearch}
          title="Zoek een locatie"
          onClick={toggleMobileSearch}
        />
        <CSSTransition
          in={mobileSearch}
          timeout={250}
          nodeRef={nodeRef}
          classNames={{
            enterActive: styles.Topbar_searchMobile___transition,
            enterDone: styles.Topbar_searchMobile___transition,
          }}
        >
          <div ref={nodeRef} className={styles.Topbar_searchMobile}>
            <div id="mobileSearchWidget" />
          </div>
        </CSSTransition>
      </div>

      <div className={styles.Topbar_actions}>
        <div className={styles.Topbar_search}>
          <div id="searchWidget" />
        </div>

        <Button
          onClick={toggleCompare}
          iconTitle="Vergelijken"
          iconSvg={IconCompare}
          type={comparing ? 'tertiary' : undefined}
        >
          {comparing ? 'Stop vergelijken' : 'Vergelijken'}
        </Button>
      </div>

      <div className={styles.Topbar_logo}>
        <img src="/images/logo-kadaster.svg" alt="Logo Kadaster" />
        <h1>Topotijdreis</h1>
      </div>

      <nav className={styles.Topbar_navigationMobile}>
        <Button
          id="toggleMobileNavigation"
          type="transparent"
          iconTitle="Plus"
          title="Open navigatie"
          iconSvg={mobileNavigationOpen ? IconClose : IconNavigation}
          onClick={toggleMobileNavigation}
        />
      </nav>

      <nav className={styles.Topbar_navigation}>
        <Button
          type="transparent"
          iconTitle="Kaart"
          iconSvg={IconMap}
          onClick={toggleInfoModal}
        >
          Over Topotijdreis
        </Button>
        <Button
          type="transparent"
          iconTitle="Delen"
          iconSvg={IconShare}
          onClick={toggleShareModal}
        >
          Delen
        </Button>
      </nav>
      <CSSTransition
        in={!!snack}
        timeout={200}
        mountOnEnter
        unmountOnExit
        onEntered={() => setTimeout(() => setSnack(false), 5000)}
        nodeRef={snackRef}
        classNames={{
          enterActive: styles.Topbar_snacks___active,
          enterDone: styles.Topbar_snacks___active,
        }}
      >
        <div ref={snackRef} className={styles.Topbar_snacks}>
          <div className={styles.Topbar_snack}>{snack}</div>
        </div>
      </CSSTransition>
    </div>
  );
}
