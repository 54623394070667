import { useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMapSettings, MapTypes } from './mapUtils';
import { createUrl, CreateUrl } from './urlUtils';

const useUrlParams = () => {
  const {
    type: typeParam,
    year: yearParam,
    typeCompare: typeCompareParam,
    yearCompare: yearCompareParam,
    mapState: mapStateParam,
  } = useParams();
  const navigate = useNavigate();
  const calculatedComparing = !!typeCompareParam && !!yearCompareParam;

  const calculatedType: MapTypes =
    typeParam === 'satelliet' ? 'satellite' : 'map';

  const calculatedTypeCompare: MapTypes =
    typeCompareParam === 'satelliet' ? 'satellite' : 'map';

  const calculatedYear = useMemo(() => {
    const mapSettings = getMapSettings(calculatedType);
    let calculatedYear =
      yearParam && parseInt(yearParam)
        ? parseInt(yearParam)
        : mapSettings.startYear;

    // If we don't have a valid year, then return the startyear
    if (
      calculatedYear < mapSettings.firstYear ||
      calculatedYear > mapSettings.lastYear
    ) {
      calculatedYear = mapSettings.startYear;
    }
    return calculatedYear;
  }, [yearParam, calculatedType]);

  // @TODO remove same function
  const calculatedYearCompare = useMemo(() => {
    const mapSettings = getMapSettings(calculatedTypeCompare);
    let calculatedYearCompare =
      yearCompareParam && parseInt(yearCompareParam)
        ? parseInt(yearCompareParam)
        : mapSettings.startYear;

    // If we don't have a valid year, then return the startyear
    if (
      calculatedYearCompare < mapSettings.firstYear ||
      calculatedYearCompare > mapSettings.lastYear
    ) {
      calculatedYearCompare = mapSettings.startYear;
    }
    return calculatedYearCompare;
  }, [yearCompareParam, calculatedTypeCompare]);

  const navigateTo = useCallback(
    (
      { type, year, typeCompare, yearCompare, mapState, comparing }: CreateUrl,
      options?: any
    ) => {
      const newType = type ?? calculatedType;
      const newTypeCompare = typeCompare ?? calculatedTypeCompare;
      navigate(
        createUrl({
          type: newType,
          year: year ?? calculatedYear,
          typeCompare: newTypeCompare,
          yearCompare: yearCompare ?? calculatedYearCompare,
          mapState: mapState ?? mapStateParam,
          comparing: comparing ?? calculatedComparing,
        }),
        options
      );
    },
    [
      calculatedYear,
      calculatedType,
      calculatedTypeCompare,
      calculatedYearCompare,
      mapStateParam,
      navigate,
      calculatedComparing,
    ]
  ); // this depends on id, and need to be recreated on id change

  return {
    mapType: calculatedType,
    mapTypeCompare: calculatedTypeCompare,
    activeYear: calculatedYear,
    activeYearCompare: calculatedYearCompare,
    comparing: calculatedComparing,
    navigateTo,
  };
};

export default useUrlParams;
