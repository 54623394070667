import { useEffect, useState } from 'react';
import Modal from './Modal';
import styles from './Modal.module.css';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Button from '../shared/Button';
import buttonStyles from '../shared/Button.module.css';
import { ReactComponent as IconTwitter } from './../icons/twitter.svg';
import { ReactComponent as IconFacebook } from './../icons/facebook.svg';
import { ReactComponent as IconEmail } from './../icons/email-action-unread.svg';
import { ReactComponent as IconLinkedin } from './../icons/linkedin.svg';
import { ReactComponent as IconWhatsapp } from './../icons/whatsapp.svg';

type ShareModalProps = {
  handleClose: () => void;
  isOpen: boolean;
};

export default function ShareModal({ handleClose, isOpen }: ShareModalProps) {
  const location = useLocation();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  useEffect(() => {
    if (!isOpen) setCopiedToClipboard(false);
  }, [isOpen]);
  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <div className={styles.Modal_content}>
        <h2>Delen</h2>
        <p>Deel Topotijdreis met iemand die ook even terug in de tijd wil.</p>
        <div
          className={classNames(
            buttonStyles.Button_group,
            buttonStyles.Button_group___margin
          )}
        >
          <Button
            as="a"
            href={`https://twitter.com/intent/tweet?url=https://www.topotijdreis.nl${location.pathname}&text=Topotijdreis:%20200%20jaar%20topografische%20kaarten&hashtags=kadaster`}
            iconTitle="Twitter"
            target="_blank"
            iconSvg={IconTwitter}
          >
            Twitter
          </Button>{' '}
          <Button
            as="a"
            href={`https://www.facebook.com/sharer/sharer.php?s=100&p%5Btitle%5D=200+Jaar+topografie+&p%5Bsummary%5D&p%5Burl%5D=https://www.topotijdreis.nl${location.pathname}`}
            iconTitle="Facebook"
            target="_blank"
            iconSvg={IconFacebook}
          >
            Facebook
          </Button>{' '}
          <Button
            as="a"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.topotijdreis.nl${location.pathname}`}
            iconTitle="LinkedIn"
            target="_blank"
            iconSvg={IconLinkedin}
          >
            LinkedIn
          </Button>
          <Button
            as="a"
            mobile
            href={`whatsapp://send?text=Topotijdreis:%20200%20jaar%20topografische%20kaarten%0Ahttps://topotijdreis.studiowolf.com${location.pathname}`}
            iconTitle="WhatsApp"
            data-action="share/whatsapp/share"
            target="_blank"
            iconSvg={IconWhatsapp}
          >
            WhatsApp
          </Button>
          <Button
            as="a"
            href={`mailto:?&subject=Topotijdreis&body=Topotijdreis:%20200%20jaar%20topografische%20kaarten.%20https://www.topotijdreis.nl${location.pathname}`}
            iconTitle="Email"
            target="_blank"
            iconSvg={IconEmail}
          >
            E-mail
          </Button>
        </div>
        <p>Kopieer de url en deel waar je maar wilt.</p>
        <div className="FormCopy">
          <input
            type="text"
            onFocus={(e) => e.target.select()}
            value={`https://www.topotijdreis.nl${location.pathname}`}
            onChange={() => {}}
          />
          <Button
            type="primary"
            onClick={() => {
              setCopiedToClipboard(true);
              navigator.clipboard.writeText(
                `https://www.topotijdreis.nl${location.pathname}`
              );
            }}
          >
            {copiedToClipboard ? 'Gekopieerd!' : 'Kopieer'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
