import App from './App';
import { Route, Routes } from 'react-router-dom';
import useLegacyUrlCompatibility from './utils/useLegacyUrlCompatibility';

export default function AppBase() {
  useLegacyUrlCompatibility();
  return (
    <Routes>
      <Route
        path="/vergelijk/:type/:year/:typeCompare/:yearCompare/:mapState"
        element={<App compare />}
      />
      <Route
        path="/vergelijk/:type/:year/:typeCompare/:yearCompare"
        element={<App compare />}
      />
      <Route path="/:type/:year/:mapState" element={<App />} />
      <Route path="/:type/:year" element={<App />} />
      <Route path="/" element={<App />} />
    </Routes>
  );
}
