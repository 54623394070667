import React from 'react';
import Modal from './Modal';
import styles from './Modal.module.css';

type InfoModalProps = {
  handleClose: () => void;
  isOpen: boolean;
};

export default function InfoModal({ handleClose, isOpen }: InfoModalProps) {
  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <div className={styles.Modal_content}>
        <h2>Over meer dan 200 jaar topografie en luchtfoto's</h2>
        <p>
          Op 18 februari 1815 werd het Topographisch Bureau opgericht. Sinds die
          datum verzamelt en ontsluit de Nederlandse overheid geografische
          informatie: bijvoorbeeld over de ligging van wegen, water, bebouwing
          en landbouwgrond. Later ging het Bureau verder onder de naam
          Topografische Dienst. In 2004 werd de Dienst onderdeel van het
          Kadaster.
        </p>
        <p>
          Hiermee haalde het Kadaster jarenlange ervaring met het verzamelen van
          geo-informatie in huis. Een resultaat om trots op te zijn: meer dan
          200 jaar topografische kaarten van topniveau. Om dit niveau te
          behouden investeren we in innovaties, waar mogelijk in samenwerking
          met overheden, het bedrijfsleven en de wetenschap. Door nieuwe
          methoden en technieken hebben gebruikers nog meer plezier van onze
          kaarten. Deze zijn actueler dan ooit.
        </p>
        <p>
          Sinds 1927 worden topografische kaarten gemaakt met behulp van
          luchtfoto's, dat zijn foto’s vanuit een vliegtuig loodrecht naar
          beneden. Vanaf die tijd zijn er van Nederland luchtfoto’s beschikbaar
          met verschillende intervallen. Vanaf 2006 is begonnen met het maken
          van digitale luchtfoto’s van (delen van) Nederland, deze worden in
          Topotijdreis getoond.
        </p>
        <p>
          Om 200 jaar topografie te vieren is in 2015 deze tijdreis-app gemaakt.
          Ontdek met de app meer dan 200 jaar aan topografische gegevens van
          Nederland en luchtfoto’s vanaf 2006!
        </p>
        <p>Veel plezier bij het gebruik van Topotijdreis !</p>
      </div>
      <div className={styles.Modal_content}>
        <h2>Disclaimer</h2>
        <p>
          Topotijdreis met luchtfoto’s is met grote zorg opgebouwd uit
          gedigitaliseerde en geogerefereerde historische kaarten en
          luchtfoto's. Toch kunnen er nog onvolkomenheden voorkomen.
        </p>
        <p>
          <a href="https://www.toegankelijkheidsverklaring.nl/register/2820">
            Toegankelijkheidsverklaring
          </a>
        </p>
        <p>
          <a href="/images/Algemene_voorwaarden_en_privacy.pdf">
            Algemene voorwaarden &amp; Privacy
          </a>
        </p>
      </div>

      <div className={styles.Modal_content}>
        <h2>Topografische kaarten</h2>
        <p>
          De kaarten zijn opgebouwd uit meerdere kaartschalen en tonen gegevens
          conform het Nederlands tiling schema op de schalen 1:12.288.000 (level
          0) t/m 1:6.000 (level 11). De gebruikte kaartprojectie is het
          Rijksdriehoekstelsel.
        </p>
        <p>
          Het kan voorkomen dat de kaarten uit de beginperiode verschoven liggen
          ten opzichte van de moderne topografie. Door de eenvoudige projectie
          die in de beginperiode is toegepast bij de oorspronkelijke
          vervaardiging van de Kraijenhoff-kaart en de Postroutekaart uit 1815
          is er geen sprake van een constante schaal. De schaal is wel verbeterd
          bij de digitale inpassing van de kaarten maar het kaartbeeld is niet
          beter te corrigeren.
        </p>
        <p>
          In Topotijdreis zijn de volgende topografische kaarten opgenomen (van
          overzicht naar detail, en van oud naar jong):
        </p>
        <h3>Postroutekaart</h3>
        <p>
          Weergave in Topotijdreis in 1815 op schaal 1 op 400.000
          <br />
          Een prachtig getekende historische topografische kaart met routes van
          postkoetsen in de tijd van Napoleon.
        </p>
        <h3>Algemene Kaart Nederland</h3>
        <p>
          Weergave in Topotijdreis van 1816 tot en met 1888 op schaal 1 op
          400.000
          <br />
          De Algemene Kaarten van Nederland van 1816, 1821 en 1830 geven een
          mooi overzicht van Nederland in die tijd.
        </p>
        <h3>Gemeentekaart</h3>
        <p>
          Weergave in Topotijdreis van 1889 tot nu op schaal 1 op 400.000
          <br />
          De Gemeentekaart van Nederland geeft een overzicht van de
          administratieve indeling van Nederland in gemeenten. Op de kaart vindt
          u onder andere rijksgrenzen, provinciegrenzen en gemeentegrenzen.{' '}
        </p>
        <h3>Topografische Kaart 200D </h3>
        <p>
          Weergave in Topotijdreis van 1867 tot en met 1995 op schaal 1 op
          200.000
          <br />
          De historische kaarten op schaal 1:200.000 zijn uitgegeven tussen 1865
          en 1955. U ziet op deze topografische kaart het terrein en landschap
          van vroeger op kleine schaal.
        </p>
        <h3>Wegenkaart</h3>
        <p>
          Weergave in Topotijdreis van 1996 tot en met 2014 op schaal 1 op
          250.000
          <br />
          De Historische wegenkaart geeft een gedetailleerd beeld van de
          Nederlandse wegen in het verleden.
        </p>
        <h3>TOP250</h3>
        <p>
          Weergave in Topotijdreis van 2015 tot nu op schaal 1 op 250.000
          <br />
          De topografische kaarten op schaal 1:250.000 worden in deze vorm
          uitgegeven sinds 2015. Deze kleine schaal geeft een goed overzicht van
          een groter gebied.
        </p>
        <h3>Krayenhoffkaart</h3>
        <p>
          Weergave in Topotijdreis van 1815 tot en met 1849 op schaal 1 op
          115.200
          <br />
          De Krayenhoffkaart is een topografische kaart van de Bataafse
          Republiek, gemaakt tussen 1798 en 1822. De kaart dankt zijn naam aan
          baron en cartograaf Krayenhoff. De laatste gewijzigde druk werd
          gemaakt in ongeveer 1870.
        </p>
        <h3>Topografische Militaire Kaart</h3>
        <p>
          Weergave in Topotijdreis van 1850 tot en met 1935 op schaal 1 op
          50.000
          <br />
          De Topografische Militaire Kaart van het Koninkrijk der Nederlanden
          verscheen van 1850 tot 1864. Door de gedetailleerde weergave is de
          kaart een uitstekende bron van informatie voor de bestudering van het
          19-eeuwse, voor-industriële Nederlandse landschap.{' '}
        </p>
        <h3>TOP50</h3>
        <p>
          Weergave in Topotijdreis van 1903 tot nu op schaal 1 op 50.000
          <br />
          Topografische kaarten op schaal 1:50.000 zijn uitgegeven tussen 1903
          en nu. De kaart is in kleur en geeft een volledig beeld van het
          terrein en het landschap, met onder meer huizen in het buitengebied,
          straten, water, bos en plaatsnamen.
        </p>
        <h3>Bonnebladen</h3>
        <p>
          Weergave in Topotijdreis van 1865 tot en met 1949 op schaal 1 op
          25.000
          <br />
          Bonnebladen zijn gedetailleerde militaire kaarten. Deze kaartbladen
          werden vanaf 1865 voor het eerst in kleur gedrukt door het voormalige
          Topographisch Bureau.
        </p>
        <h3>TOP25</h3>
        <p>
          Weergave in Topotijdreis van 1935 tot nu op schaal 1 op 25.000
          <br />
          Topografische kaarten op schaal 1:25.000 zijn uitgegeven tussen 1935
          en nu. U ziet op een topografische kaart het terrein en landschap met
          huizen, straten en plaatsnamen. Waar was bebouwing? Hoe liepen beekjes
          en rivieren? U ziet ook waar bos, hei of weidegrond was. De kaarten
          bevatten gedetailleerde topografische informatie.
        </p>
        <p>
          Bovengenoemde Topotijdreis kaarten zijn te{' '}
          <a href="https://www.kadaster.nl/producten/kaarten-en-luchtfoto-s/historisch">
            bestellen
          </a>
          .
        </p>
      </div>

      <div className={styles.Modal_content}>
        <h2>Luchtfoto’s</h2>
        <p>
          In Topotijdreis zijn momenteel alleen de digitale luchtfoto’s
          opgenomen vanaf het eerste jaar dat de bedekking van Nederland
          digitaal compleet is, en dat is 2006.
        </p>
        <p>
          De luchtfoto’s zijn vervaardigd op een schaal van ongeveer 1:18.000 en
          beslaan een gebied van circa 4 x 4 km. Alle luchtfoto’s zijn loodrecht
          naar beneden genomen, t/m 2006 in zwart/wit en vanaf 2007 zijn de
          luchtfoto's opgenomen in kleur.
        </p>
        <p>
          Er zijn analoge luchtfoto's vanaf 1927 beschikbaar, deze zijn te{' '}
          <a href="https://www.kadaster.nl/producten/kaarten-en-luchtfoto-s/historisch/luchtfoto-s">
            bestellen
          </a>
          .
        </p>
      </div>

      <div className={styles.Modal_content}>
        <h2>Veelgestelde vragen</h2>

        <p>
          <strong>
            Waarom zie ik soms geen verschil in het kaartbeeld wanneer ik wissel
            tussen jaren?
          </strong>
        </p>
        <p>
          Dit komt omdat er voor die gebieden dan geen nieuwe kaarten zijn
          gemaakt. De kaart van een specifiek jaar laat de historische
          topografische data zien die tot op dat jaar actueel beschikbaar waren.
          Dit kan betekenen dat er gedurende meerdere jaren geen verschil in de
          kaart waarneembaar is.
        </p>

        <p>
          <strong>Waarom zijn bepaalde kaarten verschillend van kleur?</strong>
        </p>
        <p>
          Destijds waren er verschillende drukkers om de topografische kaarten
          te printen. Zij hadden een eigen productieproces en manier van
          opslaan. Dit verklaart het kleurverschil.
        </p>

        <p>
          <strong>Zijn alle kaarten in kleur?</strong>
        </p>
        <p>
          Nee, per gebied en jaartal kan dit variëren. Dit heeft te maken met
          het doel waar de kaart voor gemaakt werd/wordt.
        </p>

        <p>
          <strong>Is er bij een kaart ook een legenda beschikbaar?</strong>
        </p>
        <p>
          Nee, hiervoor moet u een{' '}
          <a href="https://www.kadaster.nl/producten/kaarten-en-luchtfoto-s/historisch">
            bestelling plaatsen
          </a>
          .
        </p>

        <p>
          <strong>Kan ik een kaart ook downloaden als een PDF-bestand?</strong>
        </p>
        <p>
          Nee, wel kunt u een kaart{' '}
          <a href="https://www.kadaster.nl/producten/kaarten-en-luchtfoto-s/historisch">bestellen</a>.
        </p>

        <p>
          <strong>Staan er ook kadastrale grenzen op kaarten?</strong>
        </p>
        <p>
          Nee, alleen de topografie wordt weergegeven. Wilt u een kaart met
          kadastrale grenzen dan kunt u een{' '}
          <a href="https://www.kadaster.nl/producten/woning/kadastrale-kaart">
            kadastrale kaart opvragen
          </a>
          .
        </p>

        <p>
          <strong>
            Waarom klopt de informatie op de kaart niet met de werkelijkheid?
            Mijn huis staat niet juist weergegeven.
          </strong>
        </p>
        <p>
          Er zit een periode tussen de werkelijke opname en de feitelijke
          uitgave waarin de kaart is gemaakt.
        </p>

        <p>
          <strong>Zijn er ook kaarten aanwezig van voor 1815?</strong>
        </p>
        <p>
          Nee, voor die tijd bestond er nog geen topografische dienst. Er zijn
          wel topografische kaarten maar die zijn regionaal beschikbaar.
          Bijvoorbeeld via het Nationaal archief, dit kan per gemeente,
          provincie verschillen.
        </p>

        <p>
          <strong>
            Mag ik een print-screen maken van een kaart en dit publiceren?
          </strong>
        </p>
        <p>
          U mag de website topotijdreis niet commercieel gebruiken. Het volgende
          is niet toegestaan:
        </p>
        <ul className={styles.Modal_list}>
          <li>het vragen van betaling en/of registratie voor toegang;</li>
          <li>het toe- of invoegen van reclame-uitingen;</li>
          <li>
            logo’s of andere content (al dan niet via layers, pop-ups en
            dergelijke);
          </li>
          <li>
            het creëren van additionele functionaliteiten en afgeleide diensten.
          </li>
        </ul>
        <p>
          Overig gebruik is alleen toegestaan als daarvoor voorafgaande
          schriftelijke toestemming van het Kadaster is verkregen. Zie hiervoor
          ook{' '}
          <a href="/images/Algemene_voorwaarden_en_privacy.pdf">
            Algemene voorwaarden &amp; Privacy
          </a>
          .
        </p>

        <p>
          <strong>
            Waarom zie ik vliegvelden en kazernes niet op oudere kaarten?
          </strong>
        </p>
        <p>
          In een bepaalde periode viel dit onder geclassificeerd gebied en mocht
          niet weergegeven worden. Dit werd witgelaten of met een fantasie
          (grasvelden, slootjes) gebied weergegeven.
        </p>

        <p>
          <strong>
            Waarom is een gebouw op een kaart niet zichtbaar in het jaar van
            uitgifte?
          </strong>
        </p>
        <p>
          De kaarten worden gemaakt op basis van de luchtfoto en daar zit een
          paar jaar tussen, dit kan oplopen tot 5 jaar. Daarnaast werden vroeger
          de kaarten ook niet zo vaak herzien als tegenwoordig.
        </p>

        <p>
          <strong>Zijn er luchtfoto's van voor 2006 beschikbaar?</strong>
        </p>
        <p>
          Er zijn analoge luchtfoto's{' '}
          <a href="https://www.kadaster.nl/producten/kaarten-en-luchtfoto-s/historisch/luchtfoto-s">
            vanaf 1927 beschikbaar
          </a>
        </p>

        <p>
          <strong>Hoe goed zijn details op de foto's te onderscheiden?</strong>
        </p>
        <p>
          Het detailniveau verschilt per vluchtjaar en is afhankelijk van
          seizoensinvloeden. De luchtfoto's zijn gevlogen op een hoogte van
          ongeveer 1800 meter t.b.v. topografische kaarten op een schaal van
          1:10.000. Kleine objecten zoals schuttingen en hekken zijn niet
          zichtbaar op de foto's.
        </p>

        <p>
          <strong>
            Waarom staan er alleen luchtfoto’s vanaf 2006 in Topotijdreis?
          </strong>
        </p>
        <p>
          Vanaf 2006 zijn de luchtfoto’s digitaal beschikbaar en daarom
          makkelijk in Topotijdreis op te nemen. Voor 2006 is (een groot deel
          van) Nederland alleen met analoge luchtfoto’s opgenomen. Deze zijn
          niet geschikt voor opname in Topotijdreis.
        </p>

        <p>
          <strong>Hoe kun je het beste navigeren in de luchtfoto?</strong>
        </p>
        <p>
          In de luchtfoto’s staan geen namen ed. Het kan daarom lastig zijn om
          in de luchtfoto de gewenste locatie te vinden. Het makkelijkste is om
          op de kaart in Topotijdreis je locatie te zoeken en dan via de knop
          over te schakelen naar de luchtfoto.
        </p>

        <p>
          <strong>
            Waarom hebben de kaart en de luchtfoto niet dezelfde tijdsbalk?
          </strong>
        </p>
        <p>
          Luchtfoto’s zijn niet voor ieder jaar beschikbaar en de nieuwe
          luchtfoto’s en de nieuwe topografische kaart kunnen op verschillende
          tijdstippen beschikbaar komen.
        </p>

        <p>
          <strong>
            Waarom bevatten de kaart en de luchtfoto van hetzelfde jaartal niet
            dezelfde informatie? / Waarom zie ik wel iets op de luchtfoto, maar
            niet op de topografische kaart?
          </strong>
        </p>
        <p>
          De luchtfoto’s worden gebruikt als bron voor het actualiseren van de
          topografische kaarten. Vanwege de doorlooptijd van de processen rondom
          luchtfoto’s en topografische kaarten zijn de topografische kaarten
          meestal gebaseerd op de luchtfoto’s van het jaar ervoor. Als
          voorbeeld: De topografische kaarten uit 2019 zijn gebaseerd op
          luchtfoto’s uit 2018.
        </p>

        <p>
          <strong>In welke browser kan ik de app het beste bekijken?</strong>
        </p>
        <p>
          Gebruik de meest recente versie van uw browser. De app werkt het beste
          in Google Chrome en Mozilla Firefox.
        </p>
      </div>
    </Modal>
  );
}
