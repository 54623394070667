import { useEffectOnce } from 'react-use';
import { getMapSettings, MapTypes } from './mapUtils';
import { createUrl } from './urlUtils';

const useLegacyUrlCompatibility = () => {
  // Support legacy sharing url's
  useEffectOnce(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length !== 0) {
      const type: MapTypes =
        params.datatype === 'imagery' ? 'satellite' : 'map';
      const year = params.year
        ? parseInt(params.year as string)
        : getMapSettings(type).startYear;
      let mapState;

      if (params.x && params.y && params.l) {
        mapState = `@${parseInt(params.x as string)},${parseInt(
          params.y as string
        )},${parseInt(params.l as string)}`;
      }
      window.location.replace(createUrl({ type, year, mapState }));
    }
  });
};

export default useLegacyUrlCompatibility;
