import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import mergeRefs from 'merge-refs';
import useMeasure from 'react-use-measure';
import styles from './Timeline.module.css';
import Button from '../shared/Button';
import { ReactComponent as IconPlay } from './../icons/controls-play.svg';
import { ReactComponent as IconPause } from './../icons/controls-pause.svg';
import { ReactComponent as IconArrowLeft } from './../icons/arrow-button-left-2.svg';
import { ReactComponent as IconArrowRight } from './../icons/arrow-button-right-2.svg';
import TimelineActive from './TimelineActive';
import useUrlParams from '../utils/useUrlParams';
import { getMapSettings, MapTypes } from '../utils/mapUtils';

type TimelineProps = {
  playing: boolean;
  togglePlay: () => void;
  activeYear: number;
  mapType: MapTypes;
  secondary?: boolean;
};

export default function Timeline({
  playing,
  togglePlay,
  activeYear,
  mapType,
  secondary = false,
}: TimelineProps) {
  const { navigateTo } = useUrlParams();
  const timelineRef = React.useRef<any>(null!);
  const [measureRef, bounds] = useMeasure();
  const [hovered, setHovered] = useState(false);

  const mapSettings = getMapSettings(mapType);

  // Create year object based on settings file useMemo
  const years = useMemo(() => {
    const years = [];
    let i = mapSettings.firstYear;
    const len = mapSettings.lastYear;
    do {
      years.push(i);
    } while (++i <= len);
    return years;
  }, [mapSettings]);

  // Also allow users to click on the timeline and not only on the nozzle
  // (TimelineActive) for timlines with bigger space between the steps
  const onClick = useCallback(
    (e) => {
      const clickY = e.pageY - timelineRef.current.getBoundingClientRect().top;
      const pixelsPerYear =
        bounds.height / (mapSettings.lastYear - mapSettings.firstYear + 1);

      const steps = Math.min(
        Math.round((clickY - pixelsPerYear / 2) / pixelsPerYear),
        mapSettings.lastYear - mapSettings.firstYear
      );
      navigateTo({
        [secondary ? 'yearCompare' : 'year']: mapSettings.firstYear + steps,
      });
    },
    [bounds, mapSettings, navigateTo, secondary]
  );

  return (
    <div
      className={classNames(styles.Timeline, styles.Timeline___default, {
        [styles.Timeline___hovered]: hovered,
        [styles.Timeline___secondary]: secondary,
      })}
    >
      <div className={styles.Timeline_actions}>
        <Button
          type="opacity"
          size="small"
          iconTitle="Play"
          title="Afspelen van de tijdlijn"
          iconSvg={playing ? IconPause : IconPlay}
          onClick={togglePlay}
        />

        <select
          onChange={(e) =>
            navigateTo({
              [secondary ? 'yearCompare' : 'year']: parseInt(e.target.value),
            })
          }
          value={activeYear}
          title="Kies een jaar"
        >
          {years.map((year) => (
            <option key={year}>{year}</option>
          ))}
        </select>

        <div className={styles.Timeline_previousNext}>
          <Button
            type="opacity"
            size="smaller"
            iconTitle="Vorige"
            title="Vorig jaar"
            iconSvg={IconArrowLeft}
            onClick={() => {
              const prevYear =
                activeYear - 1 < mapSettings.firstYear
                  ? mapSettings.lastYear
                  : activeYear - 1;
              navigateTo({
                [secondary ? 'yearCompare' : 'year']: prevYear,
              });
            }}
          />
          <Button
            type="opacity"
            size="smaller"
            iconTitle="Volgende"
            title="Volgend jaar"
            onClick={() => {
              const nextYear =
                activeYear + 1 > mapSettings.lastYear
                  ? mapSettings.firstYear
                  : activeYear + 1;
              navigateTo({
                [secondary ? 'yearCompare' : 'year']: nextYear,
              });
            }}
            iconSvg={IconArrowRight}
          />
        </div>
      </div>

      <div className={styles.Timeline_wrapper}>
        <div
          className={styles.Timeline_years}
          ref={mergeRefs(measureRef, timelineRef)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onTouchStart={() => setHovered(true)}
          onTouchEndCapture={() => setHovered(false)}
          onClick={onClick}
        >
          <TimelineActive
            hovered={hovered}
            timelineRef={timelineRef}
            timelineBounds={bounds}
            activeYear={activeYear}
            mapType={mapType}
            secondary={secondary}
            onClick={(year) =>
              navigateTo({
                [secondary ? 'yearCompare' : 'year']: year,
              })
            }
          />
          {years.map((year) => {
            const includesYear = mapSettings.sliderValues.includes(year);
            return (
              <span
                className={classNames(styles.Timeline_year, {
                  [styles.Timeline_year___selected]: activeYear === year,
                  [styles.Timeline_year___line]: year % 5 === 0 || includesYear,
                  [styles.Timeline_year___label]: includesYear,
                })}
                key={year}
                // Het onclick jaartal met hetzelfde meetmechanisme bepalen als timelineactive (komt wel aan bod bij mobile)
              >
                {includesYear && <span>{year}</span>}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}
