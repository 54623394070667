import React from 'react';
import Icon from './Icon';
import classNames from 'classnames';
import styles from './Button.module.css';

type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  iconTitle?: string;
  iconSvg?: Function;
  mobile?: Boolean;
  as?: 'button' | 'a';
  type?: 'primary' | 'tertiary' | 'white' | 'opacity' | 'transparent';
  size?: 'small' | 'smaller';
  href?: string;
  title?: string;
  target?: '_blank';
  onClick?: () => void;
  id?: string;
  rel?: 'noreferrer';
};

export default function Button({
  children,
  iconTitle,
  mobile,
  className = '',
  as = 'button',
  iconSvg,
  type,
  size,
  title,
  ...props
}: ButtonProps) {
  const Tag = as;

  return (
    <Tag
      className={classNames(styles.Button, className, {
        [styles.Button___primary]: type === 'primary',
        [styles.Button___tertiary]: type === 'tertiary',
        [styles.Button___white]: type === 'white',
        [styles.Button___opacity]: type === 'opacity',
        [styles.Button___transparent]: type === 'transparent',
        [styles.Button___small]: size === 'small',
        [styles.Button___smaller]: size === 'smaller',
        [styles.Button___mobile]: mobile,
      })}
      {...props}
    >
      {children && <span className={styles.Button_title}>{children}</span>}
      {iconTitle && iconSvg && (
        <Icon className={styles.Button_icon} title={iconTitle} svg={iconSvg} />
      )}
    </Tag>
  );
}
