import { useMemo } from 'react';
import { getMapSettings } from '../utils/mapUtils';

export default function CustomAttribution({ mapType, activeYear, zoom }: any) {
  // Find the layer
  const mapLayer: any = useMemo(
    () =>
      getMapSettings(mapType).layers.find((layer) => {
        return activeYear >= layer.from && activeYear <= layer.to;
      }),
    [activeYear, mapType]
  );

  if (!mapLayer.attribution) return null;

  let attributionKey = 0;
  Object.keys(mapLayer.attribution).forEach((zoomLevel) => {
    const zoomLevelInt = parseFloat(zoomLevel);
    if (zoom > zoomLevelInt && zoomLevelInt > attributionKey)
      attributionKey = zoomLevelInt;
  });

  const attribution = mapLayer.attribution[attributionKey].text;
  const attributionUrl = mapLayer.attribution[attributionKey].url;
  if (!attribution) return null;

  return (
    <div
      className="esri-component esri-attribution esri-widget"
      style={{ position: 'absolute', bottom: 0, right: 0 }}
    >
      <div className="esri-attribution__sources" tabIndex={0} role="button">
        {attributionUrl ? (
          <a
            href={attributionUrl}
            className="esri-attribution__link"
            rel="noreferrer"
            target="_blank"
          >
            {attribution}
          </a>
        ) : (
          attribution
        )}
      </div>
      <div className="esri-attribution__powered-by">
        <a
          className="esri-attribution__link"
          href="http://www.esri.com/"
          rel="noreferrer"
        >
          Powered by Esri
        </a>
      </div>
    </div>
  );
}
