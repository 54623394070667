import React, { useRef } from 'react';
import styles from './Modal.module.css';
import { ReactComponent as IconClose } from './../icons/close.svg';
import { useClickAway, useKey } from 'react-use';
import Div100vh from 'react-div-100vh';
import { CSSTransition } from 'react-transition-group';
import Button from '../shared/Button';

type ModalProps = {
  children: React.ReactNode;
  handleClose: () => void;
  isOpen: boolean;
};

export default function Modal({ children, handleClose, isOpen }: ModalProps) {
  useKey(
    'Escape',
    () => {
      if (isOpen) handleClose();
    },
    undefined,
    [isOpen]
  );
  const modalRef = useRef(null);
  const modalInnerRef = useRef(null);
  useClickAway(modalInnerRef, () => {
    if (isOpen) handleClose();
  });

  return (
    <CSSTransition
      in={isOpen}
      timeout={250}
      mountOnEnter
      unmountOnExit
      nodeRef={modalRef}
      classNames={{
        enterActive: styles.Modal___transition,
        enterDone: styles.Modal___transition,
      }}
    >
      <Div100vh ref={modalRef} className={styles.Modal}>
        <div ref={modalInnerRef} className={styles.Modal_inner}>
          {children}
          <Button
            iconTitle="Close"
            onClick={handleClose}
            iconSvg={IconClose}
            className={styles.Modal_button}
          />
        </div>
      </Div100vh>
    </CSSTransition>
  );
}
