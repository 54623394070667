import topo from '../topo.json';
import satellite from '../satellite.json';

export const getMapSettings = (type: MapTypes) => {
  if (type === 'satellite') {
    return satellite;
  } else {
    return topo;
  }
};

export const lods = [
  { level: 0, resolution: 3251.206502413005, scale: 12288000 },
  { level: 1, resolution: 1625.6032512065026, scale: 6144000 },
  { level: 2, resolution: 812.8016256032513, scale: 3072000 },
  { level: 3, resolution: 406.40081280162565, scale: 1536000 },
  { level: 4, resolution: 203.20040640081282, scale: 768000 },
  { level: 5, resolution: 101.60020320040641, scale: 384000 },
  { level: 6, resolution: 50.800101600203206, scale: 192000 },
  { level: 7, resolution: 25.400050800101603, scale: 96000 },
  { level: 8, resolution: 12.700025400050801, scale: 48000 },
  { level: 9, resolution: 6.350012700025401, scale: 24000 },
  { level: 10, resolution: 3.1750063500127004, scale: 12000 },
  { level: 11, resolution: 1.5875031750063502, scale: 6000 },
  { level: 12, resolution: 0.7937515875031751, scale: 3000 },
  { level: 13, resolution: 0.39687579375158755, scale: 1500 },
  { level: 14, resolution: 0.19843789687579377, scale: 750 },
  { level: 15, resolution: 0.09921894843789689, scale: 375 },
  { level: 16, resolution: 0.04960947421894844, scale: 187.5 },
];

export type MapTypes = 'satellite' | 'map';
