import classNames from 'classnames';
import React from 'react';
import styles from './Map.module.css';
import ReactDOM from 'react-dom';

type ActiveYearProps = {
  hovered: boolean;
  secondary: boolean;
  children: React.ReactNode;
};

export default function ActiveYear({
  hovered,
  children,
  secondary = false,
}: ActiveYearProps) {
  let portalId = 'activeYearPortal';
  if (secondary) portalId = portalId + 'Secondary';

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.Map_year, {
        [styles.Map_year___active]: hovered,
      })}
    >
      {children}
    </div>,
    document.getElementById(portalId)!
  );
}
