import { useRef } from 'react';
import styles from './NavigationMobile.module.css';
import Button from './shared/Button';
import { ReactComponent as IconCompare } from './icons/layout-two-colums.svg';
import { ReactComponent as IconMap } from './icons/maps.svg';
import { ReactComponent as IconPlay } from './icons/controls-play.svg';
import { ReactComponent as IconPause } from './icons/controls-pause.svg';
import { ReactComponent as IconShare } from './icons/share-1.svg';
import { CSSTransition } from 'react-transition-group';
import useOnClickOutside from './utils/useOnClickOutside';
import useUrlParams from './utils/useUrlParams';
import useToggleCompare from './utils/useToggleCompare';

type NavigationMobileProps = {
  isOpen: boolean;
  toggle: (open?: boolean) => void;
  playing: boolean;
  togglePlay: (toggle?: boolean) => void;
  toggleSecondaryPlay: (toggle?: boolean) => void;
  toggleInfoModal: () => void;
  toggleShareModal: () => void;
  setSnack: (message: string | false) => void;
  modalOpen: boolean;
};

export default function NavigationMobile({
  isOpen,
  toggle,
  playing,
  togglePlay,
  toggleSecondaryPlay,
  setSnack,
  toggleInfoModal,
  toggleShareModal,
  modalOpen,
}: NavigationMobileProps) {
  const nodeRef = useRef(null);
  const toggleCompare = useToggleCompare(
    setSnack,
    togglePlay,
    toggleSecondaryPlay
  );
  const { comparing } = useUrlParams();
  useOnClickOutside(
    nodeRef,
    () => {
      if (isOpen && !modalOpen) toggle(false);
    },
    '#toggleMobileNavigation'
  );

  return (
    <CSSTransition
      in={isOpen}
      timeout={250}
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
      classNames={{
        enterActive: styles.NavigationMobile___transition,
        enterDone: styles.NavigationMobile___transition,
      }}
    >
      <div ref={nodeRef} className={styles.NavigationMobile}>
        <div className={styles.NavigationMobile_content}>
          <Button
            type="white"
            iconTitle="Plus"
            iconSvg={playing ? IconPause : IconPlay}
            onClick={togglePlay}
          >
            Play
          </Button>
          <br />
          <Button
            onClick={toggleCompare}
            type="white"
            iconTitle="Plus"
            iconSvg={IconCompare}
          >
            {comparing ? 'Stop vergelijken' : 'Vergelijken'}
          </Button>
          <br />
          <Button
            type="white"
            iconTitle="Plus"
            iconSvg={IconMap}
            onClick={toggleInfoModal}
          >
            Over Topotijdreis
          </Button>
          <br />
          <Button
            type="white"
            iconTitle="Plus"
            iconSvg={IconShare}
            onClick={toggleShareModal}
          >
            Delen
          </Button>
        </div>
      </div>
    </CSSTransition>
  );
}
