import { useCallback } from 'react';
import styles from './Timeline.module.css';
import useMouse from './../utils/useMouse';
import { getMapSettings, MapTypes } from '../utils/mapUtils';
import { RectReadOnly } from 'react-use-measure';
import classNames from 'classnames';
import ActiveYear from '../Map/ActiveYear';

type TimelineActiveProps = {
  hovered?: boolean;
  timelineRef: React.MutableRefObject<any>;
  timelineBounds: RectReadOnly;
  activeYear: number;
  mapType: MapTypes;
  onClick: (year: number) => void;
  secondary: boolean;
};

export default function TimelineActive({
  hovered = false,
  timelineRef,
  timelineBounds,
  activeYear,
  secondary = false,
  mapType,
  onClick,
}: TimelineActiveProps) {
  const ACTIVE_YEAR_HEIGHT = 34;
  // useMouse is only returns a value when we are hovered over the timeline
  const elY = useMouse(timelineRef, hovered);

  const mapSettings = getMapSettings(mapType);

  const pixelsPerYear =
    timelineBounds.height / (mapSettings.lastYear - mapSettings.firstYear + 1);

  let timelineActiveTop: number;
  let yearShown: number;

  if (hovered && elY !== null) {
    const hoverDistance = elY < 0 ? 0 : elY;

    const steps = Math.min(
      Math.round((hoverDistance - pixelsPerYear / 2) / pixelsPerYear),
      mapSettings.lastYear - mapSettings.firstYear
    );

    timelineActiveTop =
      steps * pixelsPerYear + (pixelsPerYear / 2 - ACTIVE_YEAR_HEIGHT / 2);

    yearShown = mapSettings.firstYear + steps;
  } else {
    timelineActiveTop =
      (activeYear - mapSettings.firstYear) * pixelsPerYear +
      pixelsPerYear / 2 -
      ACTIVE_YEAR_HEIGHT / 2;
    yearShown = activeYear;
  }

  const click = useCallback(
    (e) => {
      // Hier een setYear() functie van maken, zodat je 'kaart' niet mee hoeft te geven
      e.stopPropagation();
      onClick(yearShown);
    },
    [yearShown, onClick]
  );

  if (timelineBounds.height <= 0) return null;

  return (
    <div
      className={classNames(styles.Timeline_active, {
        [styles.Timeline_active___hovered]: hovered,
      })}
      style={{ top: timelineActiveTop }}
      onClick={click}
      onTouchEndCapture={click}
    >
      <div className={styles.Timeline_activeYear}>{yearShown}</div>
      <span className={styles.Timeline_activeIcon}></span>
      <ActiveYear hovered={hovered} secondary={secondary}>
        {yearShown}
      </ActiveYear>
    </div>
  );
}
