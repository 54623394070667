import { useCallback } from 'react';
import { getMapSettings, MapTypes } from '../utils/mapUtils';
import useUrlParams from '../utils/useUrlParams';
import styles from './Map.module.css';
import classNames from 'classnames';

type MapSwitcherProps = {
  setSnack: (message: string | false) => void;
  activeYear: number;
  mapType: MapTypes;
  secondary?: boolean;
};

export default function MapSwitcher({
  setSnack,
  mapType,
  secondary,
}: MapSwitcherProps) {
  const { navigateTo, activeYear } = useUrlParams();
  const switchMapType = useCallback(() => {
    if (mapType === 'satellite') {
      navigateTo({ [secondary ? 'typeCompare' : 'type']: 'map' });
    } else {
      let year = activeYear;
      let firstSatelliteYear = getMapSettings('satellite').firstYear;
      let lastSatelliteYear = getMapSettings('satellite').lastYear;
      if (activeYear < firstSatelliteYear) {
        year = firstSatelliteYear;
        // Show notification
        setSnack(`We sturen je door de tijd naar
        ${getMapSettings('satellite').firstYear}, omdat luchtfoto's sinds dat
        jaar beschikbaar zijn.`);
      } else if (activeYear > lastSatelliteYear) year = lastSatelliteYear;
      navigateTo({
        [secondary ? 'typeCompare' : 'type']: 'satellite',
        [secondary ? 'yearCompare' : 'year']: year,
      });
    }
  }, [activeYear, mapType, navigateTo, setSnack, secondary]);

  return (
    <div
      role="button"
      title="Wissel tussen kaart en luchtfoto"
      className={classNames(styles.Map_switch, {
        [styles.Map_switch___map]: mapType === 'satellite',
      })}
      onClick={switchMapType}
    />
  );
}
